<template>
  <div class="container-fluid mb-5">
    <h3>รายงาน กำไร/ขาดทุน</h3>
    <CardDateFrom :branches="[]" @selected="searchData" />

    <div class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="alert alert-info">
      <b-form-group v-if="webOptions.length" class="mb-1" label="เลือกดูเว็บไซต์:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="webFilters"
          :options="webOptions"
          :aria-describedby="ariaDescribedby"
          name="webFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
    <div class="card position-relative">
      <table class="table table-sm table-bordered mb-5">
        <thead>
          <tr>
            <th rowspan="2" class="text-center">รหัสเอเย่นต์</th>
            <th colspan="3" class="text-center">ยอดเว็บ</th>
            <th colspan="4" width="28%" class="text-center">เอเย่นต์</th>
            <th colspan="4" width="28%" class="text-center">บริษัทฯ</th>
            <th colspan="2" width="14%" class="text-center">สรุปได้-เสีย</th>
          </tr>
          <tr>
            <td width="7%" class="text-center">ยอดขาย</td>
            <td width="7%" class="text-center">ถูกรางวัล</td>
            <td width="7%" class="text-center">คงเหลือ</td>

            <td width="7%" class="text-center">ถือหุ้น</td>
            <td width="7%" class="text-center">ถูกรางวัล</td>
            <td width="7%" class="text-center">คอมฯ</td>
            <td width="7%" class="text-center">รวม</td>

            <td width="7%" class="text-center">ถือหุ้น</td>
            <td width="7%" class="text-center">ถูกรางวัล</td>
            <td width="7%" class="text-center">คอมฯ</td>
            <td width="7%" class="text-center">รวม</td>

            <td width="7%" class="text-center">Company</td>
            <td width="7%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody v-for="(group, gIndex) in groupItems">
          <tr>
            <td colspan="14" class="text-primary">เว็บ {{webname(group[0]._id.webId)}}</td>
          </tr>
          <tr v-for="(item, index) in group">
            <td class="text-center">{{ item._id.agentName }}</td>

            <td class="text-right"><span v-html="numberFormat(item.sum_sales, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_won, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_balance, 2, '0.00')"></span></td>

            <td class="text-right"><span v-html="numberFormat(item.sum_agentHold, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_agentWon, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_agentCommission, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_agentTotal, 2, '0.00')"></span></td>

            <td class="text-right"><span v-html="numberFormat(item.sum_ownerHold, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_ownerWon, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_ownerCommission, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_ownerTotal, 2, '0.00')"></span></td>

            <td class="text-right"><span v-html="numberFormat(item.sum_companyWinloss, 2, '0.00')"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.sum_ownerWinloss, 2, '0.00')"></span></td>
          </tr>

          <tr v-if="group.length > 0" class="alert-success">
            <th class="text-center">ยอดรวม {{webname(group[0]._id.webId)}}</th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].balance)"></span></th>

            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentHold)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentWon)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentCommission)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].agentTotal)"></span></th>

            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerHold)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerWon)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerCommission)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerTotal)"></span></th>

            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].companyWinloss)"></span></th>
            <th class="text-right"><span v-html="numberFormat(groupTotals[group[0]._id.webId].ownerWinloss)"></span></th>
          </tr>
        </tbody>
        <tbody v-if="!items.length">
          <tr>
            <td colspan="7" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0" style="border-top: 3px double #ccc;">
          <tr style="font-size: 110%" class="alert-info">
            <th class="text-center">รวม</th>

            <th class="text-right"><span v-html="numberFormat(total.sales, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.balance, 2, '0.00')"></span></th>

            <th class="text-right"><span v-html="numberFormat(total.agentHold, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.agentWon, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.agentCommission, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.agentTotal, 2, '0.00')"></span></th>

            <th class="text-right"><span v-html="numberFormat(total.ownerHold, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerWon, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerCommission, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerTotal, 2, '0.00')"></span></th>

            <th class="text-right"><span v-html="numberFormat(total.companyWinloss, 2, '0.00')"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerWinloss, 2, '0.00')"></span></th>
          </tr>
        </tfoot>
      </table>

      <h5 class="mb-0 text-primary pl-1">สรุปยอดรวม</h5>
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th width="70%" class="text-center">รายละเอียด</th>
            <th class="text-center">จำนวนเงิน</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-primary">
            <td colspan="2">รายรับ-รายจ่าย</td>
          </tr>
          <!--<tr>
            <td>ซ่อมจ่ายตัวถูก</td>
            <td class="text-right"><span v-html="numberFormat(total.gabAmount, 2, '0.00')"></span></td>
          </tr> -->
          <tr>
            <td>ได้-เสีย สุทธิ</td>
            <td class="text-right"><span v-html="numberFormat(total.ownerWinloss, 2, '0.00')"></span></td>
          </tr>
          <tr>
            <td>ค่าคอมฯ</td>
            <td class="text-right"><span v-html="numberFormat(total.agentTotal, 2, '0.00')"></span></td>
          </tr>
          <tr>
            <td>คีย์ผิดพลาด</td>
            <td class="text-right"><span v-html="numberFormat(otherTotal.other, 2, '0.00')"></span></td>
          </tr>
          <tr>
            <td>เงินเดือนพนักงาน</td>
            <td class="text-right"><span v-html="numberFormat(otherTotal.salary, 2, '0.00')"></span></td>
          </tr>
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(grandTotal, 2, '0.00')"></span></th>
          </tr>
        </tbody>
      </table>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
    <div class="text-right">
      <button class="btn btn-info" @click="exportToExcel"><i class="far fa-file-excel"></i> Export</button>
    </div>
    <div style="height: 100px"></div>
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'
import ReportService from '@/services/ReportService'
import CardDateFrom from '@/views/components/CardDateFrom'
import moment from '@/helpers/moment'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import _ from 'lodash'

export default {
  name: 'AdminBalance',
  components: {
    CardDateFrom,
    Loading
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      selectedBranch: null,
      data: {},
      otherData: [],
      branchFilters: [],
      webFilters: []
    }
  },
  computed: {
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start} รวม ${diff} วัน`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end} ข้อมูลทั้งหมด ${diff} วัน`
      }
    },
    Websites() {
      return this.data?.Websites || []
    },
    items() {
      if(!this.data?.Summaries)
        return []

      return this.data.Summaries.filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item._id.accountId)
      })
      .filter((item)=>{
        if(!this.webFilters.length)
          return true

        return this.webFilters.includes(item._id.webId)
      })
    },
    groupItems() {
      return _.groupBy(_.sortBy(this.items, ['_id.webId', '_id.agentName']), '_id.webId')
    },
    groupTotals() {
      let totals = {}
      for(let id in this.groupItems) {
        totals[id] = this.groupItems[id].reduce((total, d)=>{

          total.sales += parseFloat(d.sum_sales)
          total.won += parseFloat(d.sum_won)
          total.balance += parseFloat(d.sum_balance)

          total.agentHold += parseFloat(d.sum_agentHold)
          total.agentWon += parseFloat(d.sum_agentWon)
          total.agentCommission += parseFloat(d.sum_agentCommission)
          total.agentTotal += parseFloat(d.sum_agentTotal)

          total.ownerHold += parseFloat(d.sum_ownerHold)
          total.ownerWon += parseFloat(d.sum_ownerWon)
          total.ownerCommission += parseFloat(d.sum_ownerCommission)
          total.ownerTotal += parseFloat(d.sum_ownerTotal)

          total.ownerWinloss += parseFloat(d.sum_ownerWinloss)
          total.companyWinloss += parseFloat(d.sum_companyWinloss)

          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0,

          agentHold: 0,
          agentWon: 0,
          agentCommission: 0,
          agentTotal: 0,

          ownerHold: 0,
          ownerWon: 0,
          ownerCommission: 0,
          ownerTotal: 0,

          ownerWinloss: 0,
          companyWinloss: 0
        })
      }
      return totals
    },
    total() {
      return this.items.reduce((total, d)=>{
        total.sales += parseFloat(d.sum_sales)
        total.won += parseFloat(d.sum_won)
        total.balance += parseFloat(d.sum_balance)

        total.agentHold += parseFloat(d.sum_agentHold)
        total.agentWon += parseFloat(d.sum_agentWon)
        total.agentCommission += parseFloat(d.sum_agentCommission)
        total.agentTotal += parseFloat(d.sum_agentTotal)

        total.ownerHold += parseFloat(d.sum_ownerHold)
        total.ownerWon += parseFloat(d.sum_ownerWon)
        total.ownerCommission += parseFloat(d.sum_ownerCommission)
        total.ownerTotal += parseFloat(d.sum_ownerTotal)

        total.companyWinloss += parseFloat(d.sum_companyWinloss)
        total.ownerWinloss += parseFloat(d.sum_ownerWinloss)

        return total
      }, {
        sales: 0,
        won: 0,
        balance: 0,
        agentHold: 0,
        agentWon: 0,
        agentCommission: 0,
        agentTotal: 0,
        ownerHold: 0,
        ownerWon: 0,
        ownerCommission: 0,
        ownerTotal: 0,
        companyWinloss: 0,
        ownerWinloss: 0,
      })
    },
    Branchs() {
      return this.data?.Branchs || []
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    },
    webOptions() {
      return this.Websites.map((item)=>{
        return {
          text: item.item_name,
          value: item._id
        }
      })
    },
    otherItems() {
      return this.otherData.filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item.owners.accountId)
      })
    },
    otherTotal() {
      return this.otherItems.reduce((total, item)=>{
        if(AppConfig.salary.itemIds.includes(item.item_id)) {
          total.salary += parseFloat(item.amount.number)
        }else{
          total.other += parseFloat(item.amount.number)
        }
        return total
      }, {
        salary: 0,
        other: 0
      })
    },
    grandTotal() {
      // return ((this.total.commission)+this.total.gabAmount+this.otherTotal.other+this.otherTotal.salary)
      return (this.total.ownerWinloss + this.total.agentTotal + this.otherTotal.other + this.otherTotal.salary)
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date
      this.selectedBranch = params?.branch
      this.isLoading = true

      Promise.all([
        ReportService.getAgents(this.dataDate, this.selectedBranch),
        ReportService.getOthers(this.dataDate, this.selectedBranch)
      ])
      .then((values) => {
        const agentData = values[0]
        const otherData = values[1]
        this.successDataDate = this.dataDate
        if(agentData.status === 'success') {
          this.data = agentData.data
        }else{
          throw new Error()
        }

        if(agentData.status === 'success') {
          this.otherData = otherData.data.items
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2, def='-') {
      if(!number || number==='-')
        return def

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    },
    webname(id) {
      return this.Websites.find((web)=>{
        return web._id === id
      })?.item_name
    },
    exportToExcel() {

      let fileName = 'report'
      let dateText = ''
      if(this.successDataDate.start === this.successDataDate.end) {
        fileName = moment(this.successDataDate.start).format('DDMMYYYY')
        dateText = moment(this.successDataDate.start).format('D MMM YY')
      }else{
        fileName = `${moment(this.successDataDate.start).format('DDMMYYYY')}-${moment(this.successDataDate.end).format('DDMMYYYY')}`
        dateText = `${moment(this.successDataDate.start).format('D MMM YY')} - ${moment(this.successDataDate.end).format('D MMM YY')}`
      }

      const webs = []
      for(const webId in this.groupItems) {
        webs.push({
          webName: this.webname(webId),
          accounts: this.groupItems[webId]
        })
      }

      ReportService.export({
        fileName: fileName,
        dateText: dateText,
        webs: webs,
        total: this.total,
        otherTotal: this.otherTotal,
        grandTotal: this.grandTotal
      })
      .then((response)=>{
        const url = window.URL.createObjectURL(new Blob([response], {
          type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    }
  }
}
</script>
